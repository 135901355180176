import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="May 2021" subnav="release-notes">
      <div id="May2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          May packed a punch and read only options are now available on all form
          elements. See what else we were up to below.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.8.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.8.0"
            />

            <div className={styles.changesList}>
              <Added>
                <code>overflowStyle</code> prop to the{' '}
                <Link href="/components/buttons/button/code?web">Button</Link>.
              </Added>
              <Added>
                <code>isReadOnly</code> prop to the{' '}
                <Link href="/components/forms/checkbox/code?web">Checkbox</Link>
                {', '}
                <Link href="/components/forms/checkbox/code?web#Checkbox%20Group">
                  CheckboxGroup
                </Link>
                {', '}
                <Link href="/components/forms/input/code?web">Input</Link>
                {', '}
                <Link href="/components/forms/radio/code?web">Radio</Link>
                {', '}
                <Link href="/components/forms/radio/code?web#Radio%20Group">
                  RadioGroup
                </Link>
                {', '}
                <Link href="/components/forms/select/code?web">Select</Link>
                {', and'}{' '}
                <Link href="/components/forms/text-area/code?web">
                  Textarea
                </Link>
                {'.'}
              </Added>
              <Improved>
                The{' '}
                <Link href="/components/data-visualization/data-table/code?web">
                  DataTable
                </Link>{' '}
                to allow arbitrary React elements as data in cells.
              </Improved>
              <Fixed>
                An alignment issue with the{' '}
                <Link href="/components/notifications/toast/design">Toast</Link>{' '}
                content.
              </Fixed>
              <Removed>The Hudl Technique icon.</Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="React Native" />

            <div className={styles.changesList}>
              <Added>
                Option to{' '}
                <Link href="https://github.com/hudl/jarvis/tree/develop/src/uniform#reanimated-components">
                  opt-in to Reanimated2
                </Link>
                , providing improved performance for{' '}
                <Link href="/components/select-mark/code?reactnative">
                  SelectMark
                </Link>
                ,{' '}
                <Link href="/components/spinner/code?reactnative">Spinner</Link>
                , and{' '}
                <Link href="/components/notifications/toast/code?reactnative">
                  Toast
                </Link>
                .
              </Added>
              <Added>
                <code>overflowStyle</code> prop to the{' '}
                <Link href="/components/buttons/button/code?reactnative">
                  Button
                </Link>
                .
              </Added>
              <Added>
                <code>isReadOnly</code> prop to the{' '}
                <Link href="/components/forms/checkbox/code?reactnative">
                  Checkbox
                </Link>
                {', '}
                <Link href="/components/forms/checkbox/code?reactnative#Checkbox%20Group">
                  CheckboxGroup
                </Link>
                {', '}
                <Link href="/components/forms/input/code?reactnative">
                  Input
                </Link>
                {', '}
                <Link href="/components/forms/radio/code?reactnative">
                  Radio
                </Link>
                {', '}
                <Link href="/components/forms/radio/code?reactnative#Radio%20Group">
                  RadioGroup
                </Link>
                {', '}
                <Link href="/components/forms/select/code?reactnative#Single%20Select">
                  SingleSelect
                </Link>
                {', '}
                <Link href="/components/forms/select/code?reactnative#Lookup%20Select">
                  LookupSelect
                </Link>
                {', '}
                <Link href="/components/forms/select/code?reactnative#Lookup%20Multi%20Select">
                  LookupMultiSelect
                </Link>
                {', and '}
                <Link href="/components/forms/text-area/code?reactnative">
                  Textarea
                </Link>
                {'.'}
              </Added>
              <Removed>The Hudl Technique icon.</Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Uniform Version 4 Storybook documentation to{' '}
                <Link href="https://uniform-storybook.hudltools.com">
                  uniform-storybook.hudltools.com
                </Link>
              </Added>
              <Added>
                Uniform Version 3 Storybook documentation to{' '}
                <Link href="https://uniform-storybook.hudltools.com/v3">
                  uniform-storybook.hudltools.com/v3
                </Link>
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>A couple new components behind-the-scenes.</Added>
              <Removed>The Hudl Technique icon.</Removed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
